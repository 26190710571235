import { render, staticRenderFns } from "./ProfileTabs.vue?vue&type=template&id=25287e02&scoped=true&"
import script from "./ProfileTabs.vue?vue&type=script&lang=js&"
export * from "./ProfileTabs.vue?vue&type=script&lang=js&"
import style0 from "./ProfileTabs.vue?vue&type=style&index=0&id=25287e02&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25287e02",
  null
  
)

export default component.exports